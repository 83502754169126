<template>
  <Button gradient="rainbow" @click="openModal">
    Login
    <IconSmallArrowLeft class="ml-2.5" fill="white" />
  </Button>
</template>

<script lang="ts" setup>
import { Button } from '~/shared/ui/Button';
import IconSmallArrowLeft from '~/shared/assets/icons/icon-small-arrow-left.svg';
import { useAuthModal } from '~/features/auth/store/useAuthModal';

const { openModal } = useAuthModal();
</script>