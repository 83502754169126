<template>
  <div :class="styles.root">
    <Typography variant="h2" tag="div" :class="styles.title">Confirm Sign Out</Typography>
    <div :class="styles.wrapper">
      <Typography tag="p" variant="body-1" :class="styles.description">Are you sure you want to sign out of your
        account?
      </Typography>
      <div :class="styles.footer">
        <Button :class="styles.button" gradient="rainbow-border" size="large" type="button" @click="closeModal()">
          Cancel
        </Button>
        <Button :class="styles.button" size="large" type="button" @click.prevent="onLogout">Sign Out</Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAuthModal } from '~/features/auth/store/useAuthModal';
import { Typography } from '~/shared/ui/Typography';
import { Button } from '~/shared/ui/Button';
import styles from './LogoutModal.module.scss'
import { navigateTo } from '#app';
import { routerPaths } from '~/shared/config/router';

const { closeModal } = useAuthModal();
const { logout } = useSanctumAuth();

const onLogout = () => {
  logout().then(() => {
    navigateTo(routerPaths.main, {
      external: true,
    });
  });
}
</script>