<template>
  <div ref="dropdownRef" :class="styles.root">
    <button
        :class="styles.button"
        type="button"
        @click="toggleLanguageDropdown"
    >
      <IconGlobe/>
      <span :class="styles.languageCode">{{ currentLanguage }}</span>
      <IconArrow fill="black"/>
    </button>
    <div v-show="isLanguageDropdownOpen" :class="styles.dropdown">
      <ul :class="styles.languageList">
        <li
            v-for="language in locales"
            :key="language.id"
            :class="[styles.languageItem, { [styles.active]: currentLanguage === language.id }]"
            @click="changeLanguage(language.id)"
        >
          {{ language.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'; // Добавлен onMounted
import { useI18n } from 'vue-i18n';
import { onClickOutside } from '@vueuse/core';
import type { Language } from '~/entities/Language/model/types';
import IconArrow from '~/shared/assets/icons/icon-arrow-navigation.svg';
import IconGlobe from '~/shared/assets/icons/icon-globe.svg';
import styles from './Language.module.scss';

const { locale } = useI18n();

const locales: Language[] = [
  { id: 'en', name: 'English' },
  { id: 'ru', name: 'Русский' },
];

const currentLanguage = ref(locale.value);
const isLanguageDropdownOpen = ref(false);
const dropdownRef = ref<HTMLElement | null>(null);

const toggleLanguageDropdown = () => {
  isLanguageDropdownOpen.value = !isLanguageDropdownOpen.value;
};

const changeLanguage = (langCode: string) => {
  locale.value = langCode;
  currentLanguage.value = langCode;
  isLanguageDropdownOpen.value = false;
};

onMounted(() => {
  onClickOutside(dropdownRef, () => {
    isLanguageDropdownOpen.value = false;
  });
});
</script>
