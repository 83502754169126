<template>
  <div v-if="!isAccepted" :class="styles.root">
    <div :class="styles.container">
      <Typography variant="h4" tag="p">By continuing to use this site, you agree to our use of cookies.</Typography>
      <Button :class="styles.button" gradient="rainbow" @click="accepted">Accept</Button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import styles from './CookieModal.module.scss';
import { Typography } from '~/shared/ui/Typography';
import { Button } from '~/shared/ui/Button';
import { ref, type Ref } from 'vue';
import cookie from '~/shared/lib/services/cookie.service';

const COOKIE_MODAL_COOKIE_KEY = 'COOKIE_ACCEPTED';

const isAccepted: Ref<boolean> = ref(Boolean(cookie.get(COOKIE_MODAL_COOKIE_KEY)));

const accepted = (): void => {
  const expiresDate = new Date();
  expiresDate.setDate(expiresDate.getDate() + 7);

  cookie.set(COOKIE_MODAL_COOKIE_KEY, true, {
    expires: expiresDate,
  });

  isAccepted.value = true;
}
</script>