import { type CookieRef, useCookie } from '#app';
import type { CookieSerializeOptions } from 'cookie-es';

type CookieServiceInterface = CookieSerializeOptions

class CookieService {
  private readonly options: CookieServiceInterface;

  constructor(options?: CookieServiceInterface) {
    const defaultOptions = {};

    this.options = {
      ...defaultOptions,
      ...options,
    };
  }

  get<T extends string | boolean = string>(name: string, options?: CookieServiceInterface): T | null {
    const cookie: CookieRef<any> = this.merge(name, options);

    if (! cookie.value) return null;

    return cookie.value as T;
  }

  set(name: string, value: any, options?: CookieServiceInterface): void {
    const cookie: CookieRef<any> = this.merge(name, options);

    cookie.value = value;
  }

  delete(name: string, options?: CookieServiceInterface): void {
    const cookie: CookieRef<any> = this.merge(name, options);

    cookie.value = null;
  }

  private merge(name: string, options?: CookieServiceInterface): CookieRef<any> {
    return useCookie(name, {
      ...this.options,
      ...options,
    })
  }
}

export default new CookieService();