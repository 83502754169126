import useForm from '~/shared/composables/useForm';
import { ref, type Ref } from 'vue';

const useForgotten = (onCallback: () => void) => {
  const isSent: Ref<boolean> = ref(false);
  const isLoading: Ref<boolean> = ref(false);
  const form = useForm({
    email: ''
  });

  const handleSubmit = () => {
    isLoading.value = true;

    form.post('/api/client/user/forgot-password', {
      onSuccess: () => {
        form.reset();
        isSent.value = true;

        onCallback();
      },
      onFinish: () => {
        isLoading.value = false;
      }
    });
  }

  const resetIsSent = () => {
    isSent.value = false;
  }

  return {
    isSent,
    isLoading,
    form,
    handleSubmit,
    resetIsSent,
  }
}

export { useForgotten };