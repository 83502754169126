<template>
  <Modal :on-close="closeModal" :is-open="isModalOpen" :preloader="isLoading" @on-close="handleOnCloseModal">
    <AuthForm v-if="state === 'auth'" @change-loading="handleChangeLoading" />
    <ForgottenPasswordForm v-if="state === 'forgotten'" @change-loading="handleChangeLoading" />
    <LogoutModal v-if="state === 'logout'"/>
  </Modal>
</template>

<script lang="ts" setup>
import { ref, type Ref } from 'vue';
import { Modal } from '~/shared/ui/Modal';
import { storeToRefs } from 'pinia';
import { useAuthModal } from '~/features/auth/store/useAuthModal';
import { AuthForm } from '../form/AuthForm';
import { ForgottenPasswordForm } from '../form/ForgottenPasswordForm';
import { LogoutModal } from '../LogoutModal';
import { useForgotten } from '~/features/auth/composables/useForgotten';

const { isModalOpen, state } = storeToRefs(useAuthModal());
const { closeModal, changeState } = useAuthModal();
const { resetIsSent } = useForgotten();
const isLoading: Ref<boolean> = ref(false);

const handleChangeLoading = (value: boolean) => {
  isLoading.value = value;
}

const handleOnCloseModal = () => {
  changeState('auth');
  resetIsSent();
}
</script>