<template>
  <div v-if="!isSent" :class="styles.root">
    <Typography variant="h2" tag="div" class="mb-4">Password Recovery</Typography>
    <form :class="styles.wrapper" @submit.prevent="handleSubmit">
      <Typography tag="p" variant="body-1" class="mb-4">
        Please enter the email address associated with your account. We will send you instructions on how to reset your
        password.
      </Typography>
      <TextField
          v-model="form.email"
          class="w-full mb-4"
          type="email"
          label="Email:"
          placeholder="Email"
          size="large"
          :errors="form.errors.email"
      />
      <Button :class="styles.button" size="large" type="submit" :disabled="form.processing">Send</Button>
    </form>
  </div>
  <div v-else>
    <Typography variant="h2" tag="div" :class="styles.title" class="text-center">Password Reset Email Sent!</Typography>
    <div :class="styles.wrapper">
      <Typography tag="p" variant="body-1" :class="styles.description">
        A password reset email has been sent to the address you provided.
        Please check your inbox for instructions on how to reset your password.
      </Typography>
      <Button :class="styles.button" size="large" type="button" @click="changeState('auth')">Go to Login Page</Button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import styles from './ForgottenPasswordForm.module.scss';
import { Button } from '~/shared/ui/Button';
import { TextField } from '~/shared/ui/Form';
import { Typography } from '~/shared/ui/Typography';
import { useForgotten } from '../../../composables/useForgotten';

interface ForgottenPasswordEmit {
  (e: 'changeLoading', value: boolean): void;
}

const emits = defineEmits<ForgottenPasswordEmit>();
const { form, isSent, isLoading, handleSubmit } = useForgotten();

watch(isLoading, (newValue) => {
  emits('changeLoading', newValue);
});
</script>
